<template>
  <div class="set-location">
    <div class="">
      <div class="location">
        <span class="value">{{ location.title }}</span>
        <div class="coordinates"></div>
      </div>
      <div class="settings">
        <a class="button choose-units" v-if="!hideUnitSelection" @click="toggleUnits">
          <span :class="['units', 'units--feet', useFeet ? 'units-selected' : '']">
            {{ $t("units.feet") }}
          </span>
          <span :class="[
            'units',
            'units--meters',
            !useFeet ? 'units-selected' : '',
          ]">
            {{ $t("units.meters") }}
          </span>
        </a>
        <a class="change-location button" @click="toggleOpen">{{
          $t("change-location")
        }}</a>
      </div>
    </div>

    <transition name="slide">
      <div class="location-prompt" v-show="setLocationOpen">
        <label for="address" class="visuallyhidden">{{
          $t("address.label")
        }}</label>
        <div class="address-search">
          <Autocomplete v-model="item" :getResults="getResults" :format="getLabel" :setFocus="true" />
          <a id="cancel-search" class="button" @click.prevent="toggleOpen">
            {{ $t("address.cancel") }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Autocomplete from "@/components/Autocomplete.vue";
import { mapState } from "vuex";
import { encodeUrlTitle } from "@/helpers";
import bingMaps from "@/bingMaps";

export default {
  components: {
    Autocomplete
  },
  props: {
    route: {
      type: String,
      default: "location"
    }
  },
  data() {
    return {
      autosuggestions: [],
      item: null
    };
  },
  computed: {
    ...mapState([ "setLocationOpen", "location", "useFeet", "elevation" ]),
    hideUnitSelection() {
      return this.$t("units.hide-selection") === "true";
    }
  },
  methods: {
    getLabel(item) {
      if (!item) {
        return "";
      }
      if (typeof item.name === "undefined") {
        return item.address.formattedAddress;
      }
      return item.name;
    },
    getResults(query, populateResults) {
      if (query.length < 3) {
        populateResults([]);
      }
      bingMaps
        .getAutosuggestions({
          query,
          latitude: this.$store.state.location.latitude,
          longitude: this.$store.state.location.longitude
        })
        .then(results => {
          populateResults(results);
        }).catch(e => { console.warn(e) });
    },
    toggleOpen() {
      const isOpen = !this.setLocationOpen;
      this.$store.dispatch("setLocationOpen", isOpen);
      if (isOpen) {
        this.item = null;
        this.$nextTick(() => {
          document.querySelector("#autocomplete__input")?.focus();
        });
      } else {
        this.$nextTick(() => {
          document.querySelector("#autocomplete__input")?.blur();
        });
      }
    },
    toggleUnits() {
      this.$store.dispatch("setUseFeet", !this.useFeet);
    }
  },
  watch: {
    async item() {
      if (!this.item) {
        return;
      }
      let address = await bingMaps.getLocationForAutosuggestion({
        item: this.item,
        lang: this.$t.locale
      });
      this.$router
        .push({
          name: this.route,
          params: {
            latitude: `${address.point.coordinates[ 0 ]}`,
            longitude: `${address.point.coordinates[ 1 ]}`,
            title: encodeUrlTitle(
              this.item?.name || address.address.formattedAddress
            ),
            lang: this.$t.locale === "en" ? null : this.$t.locale
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
